<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-detJustificacion"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Justificacion</h4>
            <button
              type="button"
              class="close text-white"
              @click="limpiarModal"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <textarea
              class="form-control"
              aria-label="With textarea"
              v-model="novedad_id"
            ></textarea>
            <input
              type="number"
              class="form-control form-control-sm"
              
            />
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>
<script>
import axios from "axios";


export default {
  name: "TifJustificacion",
  components: {
  },

  data() {
    return {
      accion: 1,
      novedad_id: null,
      turno: null,
      //det_puntos: {},
      det_mapa: [],
    };
  },

  methods: {
    async llenar_modal_justificacion(id, turno) {
      this.novedad_id = id;
      this.turno= turno;
    },

    async getDatosTurno(turno, accion) {
      this.accion = accion;
      this.turno_id = turno;
      this.turno = {};
      await axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: turno,
            accion: accion,
          },
        })
        .then((response) => {
          this.turno = response.data[0];
        });
    },

    /* getDatosPuntos() {
      this.accion = 2;
      this.det_puntos = {};
      axios
        .get(
          "/api/hidrocarburos/ventanasEnturnamientoOperacion/ver_detalle_viaje",
          {
            params: {
              turno_id: this.turno_id,
              accion: this.accion,
            },
          }
        )
        .then((response) => {
          this.det_puntos = response.data;
        });
    }, */

    limpiarModal() {
      this.turno = {};
      this.turno_id = null;
    },
    getDatosMapa() {
      this.accion = 5;
      this.det_mapa = [];
      axios
        .get("/api/tif/turnos/ver_detalle_turno", {
          params: {
            turno_id: this.turno,
            accion: this.accion,
          },
        })
        .then((response) => {
          this.det_mapa = response.data;
        });
    },
  },

  mounted() {},
};
</script>
